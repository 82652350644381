<template>
  <div>
    <AppSpinnerBig :loading="loading"></AppSpinnerBig>

    <AppOverlay :value="addBankAccountPlaid.loading">
      <AppSpinnerBig :loading="addBankAccountPlaid.loading"></AppSpinnerBig>
    </AppOverlay>
    <BankPaymentLimitReached
      v-model="showLimitReached"
      @change="changePaymentMethod()"
    ></BankPaymentLimitReached>
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUnmount, reactive, ref } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'

import { useRouter } from '@galileo/composables/useRouter'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import BankPaymentLimitReached from '@galileo/components/Views/PaymentMethod/BankPaymentLimitReached'

import { useTransition, AppSpinnerBig, AppOverlay, useAlert } from '@oen.web.vue2/ui'
import { STEPS } from '@galileo/constants/sendMoneyFlow.const'

import {
  useI18nStore,
  useBankAccountStore,
  useAppStore,
  useAnalyticsStore,
  useAuthStore,
  useSendMoneyStore,
} from '@galileo/stores'

export default {
  name: 'PlaidOauth',
  components: {
    AppOverlay,
    AppSpinnerBig,
    BankPaymentLimitReached,
  },
  setup() {
    let plaidInstance
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const authStore = useAuthStore()
    const router = useRouter()
    const alerter = useAlert()
    const loading = ref(true)
    const animationClass = useTransition('blur', loading)
    const bankAccountStore = useBankAccountStore()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    const msgBoxCallback = () => {
      router.replace(bankAccountStore.form.plaidParentRoute)
      return true
    }
    const msgBoxExtraParams = {
      click: msgBoxCallback,
    }
    const changePaymentMethod = () => {
      msgBoxCallback()
    }

    const back = () => {
      // a bit complicated since eventually Onfido cluttered the history but didn't clean it up
      router.goBackSteps(1)
    }

    const showLimitReached = ref(false)
    const isPlaidInFlow = ref(false)
    const userProfile = authStore.userProfile

    // Plaid block
    const billingAddress = reactive({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      country: userProfile.country,
      street: userProfile.street,
      city: userProfile.city,
      state: userProfile.state,
      postalCode: userProfile.postalCode,
    })
    const addBankAccountPlaid = reactive(
      usePromiseLazy((metadata) => {
        return bankAccountStore.addBankAccountPlaid({
          accountId: metadata.account.id,
          accountType: metadata.account.subtype,
          billingAddress: billingAddress,
          maskedAccountNumber: metadata.account.mask,
          bankName: metadata.institution.name,
          plaidMetaData: metadata,
        })
      })
    )

    // TODO: create a usePlaidHelpers like in RMT
    const processPlaidSuccess = async (public_token, metadata) => {
      // the event is trigger if we get a success verification through plaid
      // this event its not related to add the bank account to our db
      analyticsStore.track({
        event: SEGMENT_EVENTS.BANK_VERIFICATION_SUCCESS,
        traits: {
          location: analyticsStore.paymentLocation,
          paymentVerificationMethod: 'Plaid',
        },
      })

      // Remove the inflow check here since we will navigate away from the success event,
      // there are router guards that will prevent navigation if this is still set at true
      isPlaidInFlow.value = false
      // Add the account to the API
      await addBankAccountPlaid.exec(metadata)
      if (addBankAccountPlaid.error) {
        appStore.logException('Exception during adding bank account', addBankAccountPlaid.error)
        switch (addBankAccountPlaid.error.errorCode) {
          case 'ClientBankAccountNumberDuplicated':
            appStore.messageBoxGenericOk(
              $t('ComponentAddPaymentBankModal.Notifications.BankAccountAlreadyExists').value,
              msgBoxExtraParams
            )
            break
          case 'ClientBankAccountLimitReached':
            showLimitReached.value = true
            break
          default:
            appStore.messageBoxGenericError(msgBoxExtraParams)
        }
      } else {
        const { bankAccount } = addBankAccountPlaid.result
        if (bankAccount.form.plaidSendMoneyRoute) {
          // IF SMF, set payment method and go to summary
          sendMoneyStore.form.paymentMethodId = bankAccount.id
          sendMoneyStore.setPaymentMethodName({
            paymentMethodTitle: bankAccount.bankName,
            paymentMethodCaption: bankAccount.maskedAccountNumber,
            paymentMethodText: bankAccount.bankName,
            paymentMethodNumber: bankAccount.maskedAccountNumber,
          })
          router.replace({ name: STEPS.SEND_MONEY_SUMMARY })
        } else {
          // Else go to parent
          router.toParentRoute()
        }
        alerter.add($t('ComponentAddPaymentBankModal.Notifications.BankAccountAdded').value)
      }
      isPlaidInFlow.value = false
    }
    const processPlaidExit = (err) => {
      if (err) {
        appStore.logException('Exception during adding bank account', err)
      }
      isPlaidInFlow.value = false
    }
    const processPlaidEvent = (eventName, metadata) => {
      if (eventName === 'ERROR' && metadata && metadata?.error_code === 'INVALID_CREDENTIALS') {
        let email = authStore.user.customer.email
        let recipientCountry = sendMoneyStore.getRecipient.country

        analyticsStore.track({
          event: SEGMENT_EVENTS.BANK_VERIFICATION_FAILED,
          traits: {
            email: email,
            recipientCountry: recipientCountry,
            xemt: false,
            location: analyticsStore.paymentLocation,
            paymentVerificationMethod: 'Plaid',
          },
        })
      }
    }

    onBeforeMount(async () => {
      try {
        await appStore.loadScriptPlaid()
        // eslint-disable-next-line no-undef
        plaidInstance = Plaid.create({
          token: bankAccountStore.getPlaidLinkToken,
          receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
          onLoad: () => {
            loading.value = false
            //emit('load')
          },
          onSuccess: async (public_token, metadata) => {
            processPlaidSuccess(public_token, metadata)
            cleanUpPlaid()
          },
          onExit: (err) => {
            processPlaidExit(err)
            cleanUpPlaid()
          },
          onEvent: (eventName, metadata) => {
            processPlaidEvent(eventName, metadata)
          },
        })
        plaidInstance.open()
      } catch (ex) {
        // TODO anything ?
        appStore.logException('Exception during getting plaid settings', ex)
        appStore.messageBoxGenericError()
      }
    })

    onBeforeUnmount(() => {
      cleanUpPlaid()
    })

    const cleanUpPlaid = () => {
      plaidInstance && plaidInstance.destroy()
    }

    return {
      back,
      loading,
      animationClass,
      $t,
      addBankAccountPlaid,
      showLimitReached,
      changePaymentMethod,
      msgBoxCallback,
    }
  },
}
</script>

<style scoped></style>
